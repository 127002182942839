import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Route from 'react-router-dom/Route'
import { BrowserRouter, Switch } from 'react-router-dom'

import { ScrollContext } from 'react-router-scroll-4';
import './index.scss';


// ** Import custom components **
import store from './store';
import App from './components/app';

// Super Admin Screens
import AdminMenus from './components/superadmin/menus'
import AdminRoles from './components/superadmin/roles'
import AdminUserRoles from './components/superadmin/userroles'

import AdminRoleMenus from './components/superadmin/assignmenustoroles'
import AdminViewSubMenus from './components/superadmin/view-submenus'
import AdminMenuReOrder from  './components/superadmin/menu-reorder'

// Dashboards
import Default from './components/dashboard/default/index.component'
import Overview from './components/dashboard/default/overview'

import Login from './components/login/login'
import EnmUserLogin from './components/login/enmuserlogin'
import Logout from './components/login/logout'
import Register  from './components/login/register'
import AuthCallBack  from './components/login/authcallback'
import ForgetPassword  from './components/pages/forget-password'
import Comingsoon  from './components/pages/comingsoon'
import Token from './components/zoho/token'
import Accounts from './components/zoho/accounts'
import Folders from './components/zoho/folders'
import ZohoMessages from './components/zoho/messages'
import AttachmentInfo from './components/zoho/attachmentinfo'
import Attachment from './components/zoho/attachment'
import RefreshToken from './components/zoho/refreshToken'
import Assets from './components/assets/assets'
import GeoFence from './components/geofence/geofence-list'
import AddGeoFence from './components/geofence/add-geofence'
import EditGeoFence from './components/geofence/edit-geofence'
import InterplantGeofence from './components/carlsberg/interplantgeofences'
import ManageTrucks from './components/manage/trucks'
import ManageConsignments from './components/manage/consignments'
import ManageSNDConsignments from './components/manage/sndconsignments'
import ManageTransporters from './components/manage/transporters'
import ReportOverspeed from './components/reports/overspeed'
import ReportEnrouteStoppages from './components/reports/enroutestoppages'
import ConsignmentsGeofence from './components/layouts/consignmentgeofence'
import ConsignmentsKpi from './components/layouts/consignmentskpi.component'
import ManageTruckConsignments from './components/layouts/truckswithconsignmentsComponent'
import DeliveredConsignmentsComponent from './components/manage/deliveredconsignments.component'
import TMS from './components/layouts/ticketmanagementsystemComponent';
import TMSInvalid from './components/layouts/ticketmanagementsystemComponent';
import ConsignmentsAllComponent from './components/manage/consignmentsall.component'
import TrucksGPSData from './components/layouts/trucksgpsdataComponent';
import Maintenance  from './components/pages/maintenance';
import OnTripGPSReport from './components/layouts/ontripgpsgpsreport';
import ManageUserMailMap from  './components/manage/usermailmap';
import ChangePasswordComponent from './components/manage/changepassword.component';
import ResetPassword from './components/login/reset-password';
import AssignRoles from './components/superadmin/assignroles';
import AndriodMap from './components/common/andriodmap';
import VicinityScreen from './components/layouts/truckwithingeofence'
import ManageNearByConsignmentTrucks from './components/manage/consignment-nearby-trucks'
import ManageSNDTransitTimes from './components/manage/sndtransittimes'
import OverspeedComponent from './components/layouts/overspeedComponent';
import StoppageReportData from './components/layouts/stoppagereportComponent';
import TransitDelayReportData from './components/layouts/transitdelayreportComponent';
import DelayAtCustomerVicinityForUnloadingData from './components/layouts/delayatcustomervicinityforunloadingdata';
import DelayAtCustomerUnloadingLocationData from './components/layouts/delayatcustomerforunloadinglocationdata';
import DepartmentTMS from './components/layouts/departmenttmsComponent';
import DashboardTemplate from './components/layouts/dashboardtemplateComponent';
import DashboardTemplate1 from './components/layouts/dashboardtemplateComponentnew';
import DashboardSummary from './components/layouts/dashboardsummaryComponent';
import DashboardDelayResponse from './components/layouts/dashboardDelayResponse';
import DashboardTickets from './components/layouts/dashboardTicketsComponent';
import DepartmentDashboard from './components/layouts/dashboarddepartmentComponent';
import ForceClosureComponent from './components/layouts/forceclosureComponent';
import TranshipmentComponent from './components/layouts/transhipmentComponent';
import TranshipmentReportComponent from './components/layouts/transhipmentReport.js';
import ManageRailConsignments from './components/layouts/railconsignmentsComponent';
import TransshipmentTransporterComponent from './components/layouts/transshipmenttransporterComponent';
import TransporterComponent from './components/layouts/transporterconsignmentsComponent';
import TransporterTickets from './components/layouts/transporterTicketComponent';
import RailTranshipment from './components/layouts/railtranshipmentComponent';
import Error404 from './components/pages/error-404';
import PossibleTransshipments from "./components/layouts/possibleTransshipments";
import StoppageReport from "./components/layouts/stoppageReport";
import TransitTimeSummary from "./components/layouts/transitTimeSummary";
import GoogleAnalytics from "./components/layouts/googelanalyticsComponent";
import DistanceTravelledReport from "./components/layouts/distancetravellreportComponent";
import UserLogReport from "./components/layouts/userlogreport";
import UserLoginReport from "./components/layouts/userloginreport";
import AndriodGridMap from './components/common/andriodgridmap';
import AndriodTPTRailMap from "./components/common/andriodtptrailmap";
import NoGPSReports from './components/layouts/nogpsreports';
import DelayInEpodFromCustomer from './components/layouts/delayinepodfromcustomer';
import ContinousTravelReport from './components/layouts/continoustravelreportComponent';
import HarshBreakingData from './components/layouts/harshbreakComponent';
import NightDrivingReportData from './components/layouts/nightdrivingReportComponent';
import chettinadShipment from "./components/carlsberg/shipmentComponent";
import chettinadShipmentRail from "./components/carlsberg/shipmentsrail";
import chettinadCreateShipment from "./components/carlsberg/create-shipment";
// import chettinadRailConsignments from "./components/carlsberg/carlsbergrailconsignmentsComponent";
import chettinadRailStockAtLoadingTerminals from "./components/carlsberg/carlsbergrailstockatloadingterminals";
import chettinadRailStockInTransit from "./components/carlsberg/carlsbergrailstockintransit";
import chettinadRailStockAtUnLoadingTerminals from "./components/carlsberg/stockatunloadingterminals";
import chettinadLoadingYardArrival from "./components/carlsberg/railloadingyardarrivalComponent";
import chettinadLastMileBatch from "./components/carlsberg/lastmilebatchComponent";
import chettinadConfirmDelivered from "./components/carlsberg/railconsignmentsdeliveredComponent";
import chettinadShipmentTrucks from "./components/carlsberg/shipmenttrucksComponent";
import chettinadCustomerEPOD from "./components/carlsberg/customerepodComponent";
import chettinadCustomerEPODShipments from "./components/carlsberg/customerepodshipmentitemsComponent";
import chettinadEPODShipments from "./components/carlsberg/epodstatusComponent";
import chettinadSimTrackDriverList from "./components/carlsberg/simtrackingdriversComponent";
import MobileTripMaps from "./components/carlsberg/mobiletripmaps";
import MobileRailMap from "./components/carlsberg/mobilerailmap";
import chettinadViewShipments from "./components/carlsberg/viewshipmenteComponent";
import chettinadPODShipmentLRNoSubmit from "./components/carlsberg/podshipmentlrnosubmitComponent";
import chettinadDeliveredTrucks from "./components/carlsberg/deliveredtrucksComponent";
import CustomerDeliveredTrucks from "./components/carlsberg/customerdeliveredtrucks";
import chettinadAllTrucks from "./components/carlsberg/alltrucksComponent";
import CustomerAllTrucks from "./components/carlsberg/customeralltrucks";
import chettinadRailSummary from "./components/carlsberg/railsummaryComponent";
import SecurityDashboardComponent from "./components/carlsberg/securitydashboardComponent";
import SecurityDashboard from "./components/carlsberg/securitydashboard";
import SimTrackingConsents from "./components/carlsberg/simtrackingconsents";
import SimTrackingJioLogs from "./components/carlsberg/simtrackingjiologs";
import ShortURLMap from "./components/common/shorturlmap";
import TrackConsignmentByInvoice from "./components/common/trackconsignmentbyinvoice";
import SimTrackingTelenityLogs from './components/carlsberg/simtrackingtelenitylogs';
import SimTrackingJioTelenityLogs from './components/carlsberg/simtrackingjiotelenitylogs';
import ShipmentTrucksTest from './components/carlsberg/shipmenttrucksComponentTest';
import DailyShipmentProgressReport from './components/carlsberg/dailyshipmentprogressreport';
import CronjobDashboard from './components/carlsberg/cronjobdashboard';
import ChangeDriver from './components/carlsberg/changedriver';
import TPTTrucksGPSStatus from './components/carlsberg/tpttruckgpsstatus';
import chettinadAllBatchTrucks from "./components/carlsberg/allbatchtrucksComponent";
import AllPriorityTrucks from './components/layouts/allprioritytrucksComponent';
import SNDUnloadingDashboard from './components/carlsberg/unloadingdashboardcomponent';
import CBDashboard from './components/carlsberg/cbdashboard.js';
import DevicesInfo from './components/carlsberg/devicesLastKnownInfo'
import DevicesSummary from './components/carlsberg/deviceSummary'
import ThirdPartyDevicesComponent from './components/layouts/thirdPartyDevices'
import DeviceMapping from './components/layouts/devicemapping'
import BillingScreen from './components/layouts/billing'
import DetentionDashboard from './components/carlsberg/detentionReportDashboard.js';
import DieselChargesDashboard from './components/carlsberg/dieselChargesDashboard.js';
import DetentionReportMailToClient from './components/carlsberg/detentionReportMail.js';
class Root extends React.Component {

    render() {
        return (
        <Provider store={store}>
            <BrowserRouter basename={'/'}>
                <ScrollContext>
                    <Switch>
 
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/enmuserlogin/:token`} component={EnmUserLogin} />
                        <Route exact path={`${process.env.PUBLIC_URL}/tptlogin`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/optirun`} component={Login} />
                        <Route exact path={`${process.env.PUBLIC_URL}/loadmanagement/login`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/loadmanagement/logout`} component={Logout} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/login`} component={Login} />
                        <Route path={`${process.env.PUBLIC_URL}/register`} component={Register} />
                        <Route path={`${process.env.PUBLIC_URL}/authcallback`} component={AuthCallBack} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/forget-password`} component={ForgetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/reset-password`} component={ResetPassword} />
                        <Route path={`${process.env.PUBLIC_URL}/pages/coming-soon`} component={Comingsoon} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/token`} component={Token} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/accounts`} component={Accounts} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/folders/:accountId`} component={Folders} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/messages/:accountId/:folderId/:folderName`} component={ZohoMessages} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/attachmentinfo/:accountId/:folderId/:messageId/:subject/:folderName`} component={AttachmentInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/attachment/:accountId/:folderId/:messageId/:attachmentId/:attachmentName/:subject`} component={Attachment} />
                        <Route path={`${process.env.PUBLIC_URL}/zoho/refreshToken`} component={RefreshToken} />
                         
                        <Route path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance} />
                        <Route path={`${process.env.PUBLIC_URL}/registration/activation`} component={Comingsoon} />


                        <Route path={`${process.env.PUBLIC_URL}/resetpassword`} component={ResetPassword} />

                        <Route path={`${process.env.PUBLIC_URL}/usermailmap/:type/:ticketid`} component={ManageUserMailMap} />
                        <Route path={`${process.env.PUBLIC_URL}/map/:type/:code/:leg`} component={AndriodMap} />
                        
                        {/* <Route path={`${process.env.PUBLIC_URL}/mobiletripmap/:shipmentid`} component={MobileTripMaps} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/mobiletripmap/:shipmentid/:iscustomer`} component={MobileTripMaps} />
                        <Route path={`${process.env.PUBLIC_URL}/mobilerailmap/:tripid/:iscustomer`} component={MobileRailMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mobile/:screen/:user/:code/:sdate/:edate/:frequency`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mobilemapview/:screen/:user/:dept/:code/:trip_type`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mobilemap/:screen/:truckno`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mapview/:screen/:user/:code/:sdate/:edate`} component={AndriodGridMap} />
                        <Route path={`${process.env.PUBLIC_URL}/railmap/:vehicle_id/:dealer_code/:truck_no/:sdate/:edate`} component={AndriodTPTRailMap} />
                        <Route path={`${process.env.PUBLIC_URL}/connect/:truck_no`} component={ShortURLMap} />
                        <Route path={`${process.env.PUBLIC_URL}/mapconnect/:invoice_no`} component={TrackConsignmentByInvoice} />  
                        <Route path={`${process.env.PUBLIC_URL}/approveDetentionReport`} component={DetentionReportMailToClient} />

                       
                       <App>
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/default`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/snddefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/prtdefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/tnpdefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/scdefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard/overview`} component={Overview} />
                        <Route path={`${process.env.PUBLIC_URL}/assets`} component={Assets} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/geofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sndgeofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/prtgeofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/tnpgeofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/scgeofences`} component={GeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/addgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sndaddgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/prtaddgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/tnpaddgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/scaddgeofence`} component={AddGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/editGeofence/:geofencename`} component={EditGeoFence} />
                        <Route path={`${process.env.PUBLIC_URL}/interplantgeofence`} component={InterplantGeofence} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/trucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sndtrucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/prttrucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/tnptrucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sctrucks`} component={ManageTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/consignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/sndconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/transporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sndtransporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/prttransporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/tnptransporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/manage/sctransporters`} component={ManageTransporters} />
                        <Route path={`${process.env.PUBLIC_URL}/sndconsignees`} component={ManageSNDConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/overspeed`} component={ReportOverspeed} />
                        <Route path={`${process.env.PUBLIC_URL}/enroutestop`} component={ReportEnrouteStoppages} />
                        <Route path={`${process.env.PUBLIC_URL}/sndgeofence`} component={ConsignmentsGeofence} />

                        <Route path={`${process.env.PUBLIC_URL}/sndkpiintransit`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpinightdriving`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpioverspeeding`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpienroute`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpiunloading`} component={ConsignmentsKpi} />
                        <Route path={`${process.env.PUBLIC_URL}/sndkpiloading`} component={ConsignmentsKpi} />

                        <Route path={`${process.env.PUBLIC_URL}/superadmin/menus`} component={AdminMenus} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/roles`} component={AdminRoles} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/usermenus`} component={AdminUserRoles} />
                        <Route path={`${process.env.PUBLIC_URL}/assignmenus`} component={AdminRoleMenus} />
                        <Route path={`${process.env.PUBLIC_URL}/viewSubmenus/:parentmenu`} component={AdminViewSubMenus} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/menuposition`} component={AdminMenuReOrder} />
                        <Route path={`${process.env.PUBLIC_URL}/superadmin/assignroles`} component={AssignRoles} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/sndtrucks`} component={ManageTruckConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtrucksatdealer`} component={ManageTruckConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/logout`} component={Logout} />
                        <Route path={`${process.env.PUBLIC_URL}/deliveredconsignments`} component={DeliveredConsignmentsComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/snddeliveredconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/ticketmanagementsystem`} component={TMS} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/allconsignments`} component={ConsignmentsAllComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndallconsignments`} component={ManageConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/trucksgps`} component={TrucksGPSData} />
                        <Route path={`${process.env.PUBLIC_URL}/railconsignments`} component={ManageRailConsignments} />
                        <Route path={`${process.env.PUBLIC_URL}/deliveredrailconsignments`} component={ManageRailConsignments} />                      
                        <Route path={`${process.env.PUBLIC_URL}/sndontripgps`} component={OnTripGPSReport} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/profile/changepassword`} component={ChangePasswordComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/prttickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/sctickets`} component={DepartmentTMS} />
                        
                        <Route path={`${process.env.PUBLIC_URL}/sndclosetickets`} component={DepartmentTMS} />
                        <Route path={`${process.env.PUBLIC_URL}/closetickets`} component={TMS} />
                        <Route path={`${process.env.PUBLIC_URL}/invalidtickets`} component={TMSInvalid} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboardtemplate`} component={DashboardTemplate} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboardtemplate1`} component={DashboardTemplate1} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboardsummary`} component={DashboardSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/criticaldelayreasons`} component={DashboardDelayResponse} />
                        <Route path={`${process.env.PUBLIC_URL}/sndcriticaldelayreasons`} component={DashboardDelayResponse} />
                        <Route path={`${process.env.PUBLIC_URL}/couriercriticaldelayreasons`} component={DashboardDelayResponse} />
                        <Route path={`${process.env.PUBLIC_URL}/ticketsdashboard`} component={DashboardTickets} />
                        <Route path={`${process.env.PUBLIC_URL}/snddashboardtemplate`} component={DepartmentDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpdashboardtemplate`} component={DepartmentDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdashboardtemplate`} component={DepartmentDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/scdashboardtemplate`} component={DepartmentDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/sndforceclosure`} component={ForceClosureComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prtforceclosure`} component={ForceClosureComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpforceclosure`} component={ForceClosureComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtranshipments`} component={TranshipmentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttranshipments`} component={TranshipmentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptranshipments`} component={TranshipmentComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtransshipments`} component={TransshipmentTransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransshipments`} component={TransshipmentTransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptransshipments`} component={TransshipmentTransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/snddelivertransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prtdelivertransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpdelivertransporterconsignments`} component={TransporterComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/transportertickets`} component={TransporterTickets} />
                        

                        <Route path={`${process.env.PUBLIC_URL}/sndtranshipmentreports`} component={TranshipmentReportComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/prttranshipmentreports`} component={TranshipmentReportComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/tnptranshipmentreports`} component={TranshipmentReportComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/railtranshipmentreports`} component={TranshipmentReportComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/sndrailtransshipments`} component={RailTranshipment} />
                        <Route path={`${process.env.PUBLIC_URL}/prtrailtransshipments`} component={RailTranshipment} />
                        <Route path={`${process.env.PUBLIC_URL}/tnprailtransshipments`} component={RailTranshipment} />
                        <Route path={`${process.env.PUBLIC_URL}/error-404`} component={Error404} />
                        <Route path={`${process.env.PUBLIC_URL}/Possibletransshipments`} component={PossibleTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/sndPossibletransshipments`} component={PossibleTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/prtPossibletransshipments`} component={PossibleTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/tnpPossibletransshipments`} component={PossibleTransshipments} />
                        <Route path={`${process.env.PUBLIC_URL}/sndStoppageReport`} component={StoppageReport} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtransittimesummary`} component={TransitTimeSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/snddepartmentvicinity/sndg`} component={VicinityScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/nearbysndtrucks`} component={ManageNearByConsignmentTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/sndtransittimes`} component={ManageSNDTransitTimes} />
                        <Route path={`${process.env.PUBLIC_URL}/overspeedreport`} component={OverspeedComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/stoppagereport`} component={StoppageReportData} />
                        <Route path={`${process.env.PUBLIC_URL}/transitdelayreport`} component={TransitDelayReportData} />
                        <Route path={`${process.env.PUBLIC_URL}/delayatcustomervicinityreport`} component={DelayAtCustomerVicinityForUnloadingData} />
                        <Route path={`${process.env.PUBLIC_URL}/delayatcustomerforunloadinglocationreport`} component={DelayAtCustomerUnloadingLocationData} />
                        <Route path={`${process.env.PUBLIC_URL}/analytics`} component={GoogleAnalytics} />
                        <Route path={`${process.env.PUBLIC_URL}/snddistancetravelreport`} component={DistanceTravelledReport} />
                        <Route path={`${process.env.PUBLIC_URL}/userlogreport`} component={UserLogReport} />
                        <Route path={`${process.env.PUBLIC_URL}/userloginreport`} component={UserLoginReport} />
                        <Route path={`${process.env.PUBLIC_URL}/raildefault`} component={Default} />
                        <Route path={`${process.env.PUBLIC_URL}/nogpsreport`} component={NoGPSReports} />
                        <Route path={`${process.env.PUBLIC_URL}/delayinepodfromcustomer`} component={DelayInEpodFromCustomer} />
                        <Route path={`${process.env.PUBLIC_URL}/continoustravelreport`} component={ContinousTravelReport} />
                        <Route path={`${process.env.PUBLIC_URL}/harshbreakingreport`} component={HarshBreakingData} />
                        <Route path={`${process.env.PUBLIC_URL}/nightdrivingreportdata`} component={NightDrivingReportData} />

                        <Route path={`${process.env.PUBLIC_URL}/shipment`} component={chettinadShipment} />
                        <Route path={`${process.env.PUBLIC_URL}/create-shipment`} component={chettinadCreateShipment} />
                        {/* <Route path={`${process.env.PUBLIC_URL}/rail-consignments/:rake_no`} component={chettinadRailConsignments} /> */}
                        <Route path={`${process.env.PUBLIC_URL}/stockatloadingterminals`} component={chettinadRailStockAtLoadingTerminals} />
                        <Route path={`${process.env.PUBLIC_URL}/railstockintransit`} component={chettinadRailStockInTransit} />
                        <Route path={`${process.env.PUBLIC_URL}/stockatunloadingterminals`} component={chettinadRailStockAtUnLoadingTerminals} />
                        <Route path={`${process.env.PUBLIC_URL}/railloadingyardarrival/:rake_no`} component={chettinadLoadingYardArrival} />
                        <Route path={`${process.env.PUBLIC_URL}/lastmilebatch/:rake_no`} component={chettinadLastMileBatch} />
                        <Route path={`${process.env.PUBLIC_URL}/raildeliveredconfirm/:rake_no`} component={chettinadConfirmDelivered} />
                        <Route path={`${process.env.PUBLIC_URL}/railshipments`} component={chettinadShipmentRail} />
                        <Route path={`${process.env.PUBLIC_URL}/shipmenttrucks`} component={chettinadShipmentTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/epods`} component={chettinadCustomerEPOD} />
                        <Route path={`${process.env.PUBLIC_URL}/podshipments/:shipment_id`} component={chettinadCustomerEPODShipments} />
                        <Route path={`${process.env.PUBLIC_URL}/podstatus`} component={chettinadEPODShipments} />
                        <Route path={`${process.env.PUBLIC_URL}/simtrackdrivers`} component={chettinadSimTrackDriverList} />
                        <Route path={`${process.env.PUBLIC_URL}/viewshipments`} component={chettinadViewShipments} />
                        <Route path={`${process.env.PUBLIC_URL}/submitlrnos/:shipment_id`} component={chettinadPODShipmentLRNoSubmit} />
                        <Route path={`${process.env.PUBLIC_URL}/deliveredtrips`} component={chettinadDeliveredTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/customerdeliveredtrips`} component={CustomerDeliveredTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/alltrips`} component={chettinadAllTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/customeralltrips`} component={CustomerAllTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/railsummary`} component={chettinadRailSummary} />
                        <Route path={`${process.env.PUBLIC_URL}/s-dashboard`} component={SecurityDashboardComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/securitydashboard`} component={SecurityDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/simtrackingconsents`} component={SimTrackingConsents} />
                        <Route path={`${process.env.PUBLIC_URL}/simtrackingjiologs`} component={SimTrackingJioLogs} />
                        <Route path={`${process.env.PUBLIC_URL}/simtrackingtelenitylogs`} component={SimTrackingTelenityLogs} />
                        <Route path={`${process.env.PUBLIC_URL}/simtrackingjioandtelenitylogs`} component={SimTrackingJioTelenityLogs} />
                        <Route path={`${process.env.PUBLIC_URL}/shipmenttruckstest`} component={ShipmentTrucksTest} />
                        <Route path={`${process.env.PUBLIC_URL}/dailyshipmentprogressreport`} component={DailyShipmentProgressReport} />
                        <Route path={`${process.env.PUBLIC_URL}/cronjobdashboard`} component={CronjobDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/changedriver`} component={ChangeDriver} />
                        <Route path={`${process.env.PUBLIC_URL}/tpttruckgpsstatus`} component={TPTTrucksGPSStatus} />
                        <Route path={`${process.env.PUBLIC_URL}/allbatchtrips`} component={chettinadAllBatchTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/allprioritydata`} component={AllPriorityTrucks} />
                        <Route path={`${process.env.PUBLIC_URL}/sndunloadingdashboard`} component={SNDUnloadingDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/dashboard`} component={CBDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/devicesinfo`} component={DevicesInfo} />
                        <Route path={`${process.env.PUBLIC_URL}/devicesummary`} component={DevicesSummary} /> 
                        <Route path={`${process.env.PUBLIC_URL}/nonenmovildevices`} component={ThirdPartyDevicesComponent} />
                        <Route path={`${process.env.PUBLIC_URL}/device`} component={DeviceMapping} />
                        <Route path={`${process.env.PUBLIC_URL}/billing`} component={BillingScreen} />
                        <Route path={`${process.env.PUBLIC_URL}/detentionDashboard`} component={DetentionDashboard} />
                        <Route path={`${process.env.PUBLIC_URL}/dieselPriceReport`} component={DieselChargesDashboard} />
                    </App>
                        
                    </Switch>
                </ScrollContext>
            </BrowserRouter>
        </Provider>
        )
    }
}



ReactDOM.render(<Root/>, document.getElementById('root'));


