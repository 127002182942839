import React, { Component } from 'react';
var redirectURL = require('../redirectURL');

export default class DetentionReportMail extends Component{

	constructor(props){
		super(props);
		this.state={
            response_message : "Please wait, processing...",
			loadshow:'show-n',
			showDiv:'show-n',
			overly:'show-n',
            show: false,
            basicTitle:'',
			basicType:"default",
		}
		
	}
    componentDidMount = async () => {
        this.setState({
            loadshow:"show-m"
        })
        redirectURL.post("/consignments/approveDetentionReport").then(async (response) => {
            if(response.data.status == "Success"){
                this.setState({
                    response_message : "Successfully Approved and Sent to Client",
                    loadshow:"show-n"
                })
            }
            else{
                this.setState({
                    response_message : "Something went wrong while approving the report.",
                    loadshow:"show-n"
                })
            }
        })
    }
   
    render() {
        return (
            <div className="container-fluid card" style = {{ height: '100vh' }}>
                <div className='row mt-3'>
                    <div className="col-xl-12 col-lg-12 col-sm-12">
                        <h6 style = {{color: "#333"}}>{this.state.response_message}</h6>
                    </div>
                </div>

                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
            </div>
        );
    }
}